/* 
.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
} */

  /* .bg-blue-gradient { */
    /* background: linear-gradient(109.5deg, rgb(76, 221, 242) 11.2%, rgb(92, 121, 255) 91.1%); */
   /* } 


/*   
   .flip-card-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: center;
    transform-style: preserve-3d;
    transition: transform 0.3s ease-in-out;
  }
  
  
  .flip-card-front {
    position: relative;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    z-index: 1;
  }
  
  .flip-card-back {
    position: absolute;
    bottom: -100%; 
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: bottom 0.6s ease-in-out;
  }
  
  .flip-card-inner:hover .flip-card-back {
    bottom: 0; 
  }
  
  .flip-card-inner:hover .flip-card-front {
    opacity: 0;
  }  */


 .flip-card {
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
  }
  
  .flip-card-front {
    z-index: 2;
    transform: translateY(0);
  }
  
  .flip-card-back {
    z-index: 1;
    transform: translateY(100%);
  }
  
  .flip-card:hover .flip-card-front {
    transform: translateY(-100%);
  }
  
  .flip-card:hover .flip-card-back {
    transform: translateY(0);
  }

