.gradient-overlay {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 0%, #00040f 100%),
                linear-gradient(to top, transparent 0%, #00040f 100%);

    pointer-events: none;
  }
  #videoBg {
    position: relative;
    min-height: 100vh;
  }
/* .gradient-overlay-top {
  background: linear-gradient(to top, transparent 5%, #00040f 100%);
  pointer-events: none;

} */

@media screen and (max-width: 480px) {
  .fix-mb-small-screen {
      margin-bottom: 0;
      height: 100vh; /* Ensure this is set for smaller screens */
  }
  .gradient-overlay {
    height: 100vh; /* Ensure overlay also covers full height */
  }
}