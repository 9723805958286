.card-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
    padding: 1rem; /* Optional: Add some padding for better appearance */
  }
  
  .card-item {
    flex: 0 0 auto; /* Ensure the cards don't shrink or grow */
    margin-right: 16px; /* Add some spacing between the cards */
  }

  
  @media screen and (max-width: 380px)  {
    #welcome {
      top: -35rem;
      margin-bottom: -35rem;
    }
  }

  @media screen and (max-width: 425px)  {
    #welcome {
      top: -30rem;
      margin-bottom: -30rem;
    }
  }